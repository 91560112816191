<template>
  <div id="trip">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />评价详情
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onReplyClick"
        >
          确认回复
        </van-col>
      </van-row>
    </div>
    <div class="feedbacks-body">
      <van-row>
        <van-col span="24">
          {{ item.feedback_content ? item.feedback_content : "暂无评价内容" }}
        </van-col>
        <van-col span="24">
          <van-tag
            style="margin-right: 3px"
            round
            type="primary"
            v-for="(i, index) in item.tags"
            :key="index"
            >{{ i }}</van-tag
          >
        </van-col>
        <van-col span="24">
          <van-rate
            v-model="item.rate_star"
            :size="25"
            color="#ffd21e"
            void-icon="star"
            disabled-color="#eee"
            readonly
            allow-half
          />
        </van-col>
        <van-col
          span="12"
          style="color: #666; font-size: 0.2rem; text-align: left"
        >
          {{ item.user_name }}
        </van-col>
        <van-col
          span="12"
          style="color: #666; font-size: 0.2rem; text-align: right"
        >
          {{ item.created_at }}
        </van-col>
      </van-row>
      <!-- <h3 style="margin-top: 10px; margin-bottom: 10px">回复</h3> -->
        <van-divider content-position="left">回复</van-divider>
      <van-row
        class="replies-container"
        v-for="(reply, index) in item.replies"
        :key="index"
      >
        <van-col span="24">
          {{ reply.content }}
        </van-col>
        <van-col
          span="12"
          style="color: #666; font-size: 0.2rem; text-align: left"
        >
          {{ reply.userable_name }}
        </van-col>
        <van-col
          span="12"
          style="color: #666; font-size: 0.2rem; text-align: right"
        >
          {{ reply.reply_at }}
        </van-col>
       
      </van-row>
       <!-- <van-divider dashed></van-divider> -->
      <van-empty v-if="item.replies.length == 0" description="暂无回复" />
      <van-row class="reply-container">
        <van-col span="24">
          <textarea
            class="reply-text-area"
            v-model="reply.content"
            placeholder="请输入回复"
            name=""
            id=""
            cols="20"
            rows="5"
          ></textarea>
        </van-col>
      </van-row>
       <div class="btn" @click="onReplyClick">确认回复</div>
    </div>
    <!-- <Tarbar :active="2"></Tarbar> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "trip",
  components: {
    Tarbar,
  },
  data() {
    return {
      plans: {},
      active: 1,
      show: false,
      item: {
        replies: [],
      },
      loading: true,
      finished: false,
      refreshing: false,
      reply: {
        content: "",
      },
      query: {
        page: 1,
        plan_name: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async onReplyClick() {
      const id = this.$route.query.id;
      if (this.reply.content == "") {
        Toast("请输入回复内容");
        return false;
      }
      const res = await this.$apis.reply_feedbacks(id, { reply: this.reply });
      if (res.status == 200) {
        this.reply.content = "";
        this.get_feedback(id);
      }
    },
    async get_feedback(id) {
      const res = await this.$apis.get_feedback(id);
      this.item = res.data;
    },
  },
  created() {
    const id = this.$route.query.id;
    this.get_feedback(id);
  },
};
</script>

<style scoped>
#trip{
  height: 100vh;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.feedbacks-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
  position: relative;
  background-color: #fff;
  font-size: 0.3rem;
}
.reply-container {
  margin-top: 20px;
}
.reply-text-area {
  border-radius: 0.2rem;
  background: #f3f3f3;
  width: 100%;
  padding: 0.3rem;
}
.btn{
  height: .8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: .8rem;
  margin-top:.4rem;
  font-size: .3rem;
  border-radius: 5px;
}
</style>
